

import { ref } from 'vue'

export default {
  data() {
    return {

      form : {
        id : '',
        judul : '',
        isi : '',
      },
     
      list_data : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      mdl_lihat: false,
      mdl_gambar: false,
      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_Perda + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    // addData : function() {
    //   var formData = new FormData();
    //   formData.append('data', JSON.stringify(this.form))
    //   formData.append('file', this.form.foto)

    //   console.log(formData);

    //   fetch(this.$store.state.url.URL_Berita + "addData", {
    //       method: "POST",
    //       headers: {
    //         authorization: "kikensbatara " + localStorage.token
    //       },
    //       body: formData
    //   }).then(res_data => {
    //       this.Notify('Sukses Menambah Data', 'secondary', 'check_circle_outline');
    //       console.log(res_data);
    //       this.getView();
    //   });
    // },

    // addData : function() {
    //     var formData = new FormData();
    //     formData.append('data', JSON.stringify(this.form))
    //     formData.append('file', this.form.foto)
  
    //     console.log(this.form.foto);
    //     console.log(formData);
  
    //     fetch(this.$store.state.url.URL_Berita + "addData", {
    //         method: "POST",
    //         headers: {
    //           // "content-type": "application/json",
    //           authorization: "kikensbatara " + localStorage.token
    //         },
    //         body: formData
    //     }).then(res_data => {
    //         this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');


    //         this.getView();
    //     });
    //   },

    addData : function() {
        
        fetch(this.$store.state.url.URL_Perda + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
            form : this.form
          })
            
        }).then(res_data => {
            this.getView();
            console.log(this.form);
           this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
        });


      },


      editData : function(){
      fetch(this.$store.state.url.URL_Perda + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            form : this.form
          })
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_Perda + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData: function(data) {
        console.log(data);
        this.form.id = data.id
        this.form.judul = data.judul
        this.form.isi = data.isi
    },

    // getKategori: function() {
    //     fetch(this.$store.state.url.URL_Kategori, {
    //         method: "GET",
    //         headers: {
    //         "content-type": "application/json",
    //         authorization: "kikensbatara " + localStorage.token
    //         }
    //     })
    //     .then(res => res.json())
    //     .then(res_data => {
    //         // console.log('telasooooo')
    //     this.list_kategori = res_data;
    //     console.log(res_data)
    //     });
    // },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        onFileSelected: function(event) {
            this.form.foto = event.target.files[0];
          },


    // ====================================== PAGINATE ====================================
  },

  mounted () {
    this.getView();
    // this.getKategori();
  },
}
